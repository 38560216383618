
import { computed, defineComponent, onMounted, ref } from 'vue'
import { MqResponsive } from 'vue3-mq'
import { useRouter } from 'vue-router'
import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'

import { Stream } from '@web/store/types/modules/stream'

import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import i18n from '@web/plugins/i18n'

import CMaterials from '@web/components.v2/Materials.vue'
import CMaterial from '@web/components.v2/Material.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsRow from '@web/components/AsRow.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import BottomNavigation from '@web/components.v2/BottomNavigation.vue'

export default defineComponent({
  name: 'VMaterials',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsRow,
    AsMenu,
    AsHeaderMain,
    AsBreadcrumb,
    BottomNavigation,
    AsPreloader,
    CMaterials,
    CMaterial
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const title = i18n.global.t('documents')

    useMeta({ title })

    const isLoaded = ref(false)
    const isOpenMenu = ref(false)

    const streamId = computed<number>(() => Number(router.currentRoute.value.params.streamId || 0))
    const stream = computed<Stream | undefined>(() => store.getters['stream/getStreamById'](streamId.value))
    const course = computed<Course | undefined>(() => {
      if (!stream.value) {
        return undefined
      }
      return store.getters['course/getCourseById'](stream.value.courseId)
    })
    const isEmpty = computed(() => !course.value?.documents.length && !course.value?.instructionUrl)
    const courseTitle = computed(() => {
      return course.value?.title || ''
    })

    function goBack () {
      if (router.options.history.state.back) {
        router.back()
      } else {
        router.push({ name: 'calendar', params: { id: streamId.value } })
      }
    }

    onMounted(async () => {
      try {
        isLoaded.value = false
        const stream = await store.dispatch('stream/fetchUserCourseStreamCached', { id: streamId.value })
        await store.dispatch('course/fetchCourseCached', { id: stream.courseId })
      } catch (e) {
        sentry.captureException(e)
        Logger.error(e)
      } finally {
        isLoaded.value = true
      }
    })

    return {
      title,
      isLoaded,
      streamId,
      stream,
      course,
      courseTitle,
      isEmpty,
      isOpenMenu,
      menu: computed(() => store.getters['menu/general']),
      goBack
    }
  }
})
