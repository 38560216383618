import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7f962d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cMaterials" }
const _hoisted_2 = {
  key: 0,
  class: "cMaterials_title"
}
const _hoisted_3 = { class: "cMaterials_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      (_ctx.showTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('documents')), 1))
        : _createCommentVNode("", true)
    ], true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}