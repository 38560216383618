
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CMaterials',
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  }
})
