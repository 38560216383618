import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db88602c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "lCommon page-materials",
  "data-cy": "page_materials"
}
const _hoisted_2 = {
  key: 1,
  class: "page-materials_empty"
}
const _hoisted_3 = {
  key: 1,
  class: "page-materials_preloader"
}
const _hoisted_4 = { class: "page-materials_materials_title" }
const _hoisted_5 = {
  key: 1,
  class: "page-materials_empty"
}
const _hoisted_6 = {
  key: 1,
  class: "page-materials_preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_c_material = _resolveComponent("c-material")!
  const _component_c_materials = _resolveComponent("c-materials")!
  const _component_bottom_navigation = _resolveComponent("bottom-navigation")!
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_row = _resolveComponent("as-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, { target: "mobile-tablet" }, {
      default: _withCtx(() => [
        (_ctx.isLoaded)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.course)
                ? (_openBlock(), _createBlock(_component_as_header_mobile, {
                    key: 0,
                    title: _ctx.courseTitle,
                    onGoBack: _ctx.goBack,
                    "custom-back": "",
                    "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
                  }, null, 8, ["title", "onGoBack"]))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_c_materials, {
                class: "page-materials_materials",
                "show-title": false
              }, {
                default: _withCtx(() => [
                  (!_ctx.isEmpty)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.documents, (doc) => {
                          return (_openBlock(), _createBlock(_component_c_material, {
                            key: doc.id,
                            doc: doc,
                            class: "page-materials_material"
                          }, null, 8, ["doc"]))
                        }), 128)),
                        _createVNode(_component_c_material, {
                          doc: {
                      url: _ctx.course.instructionUrl,
                      title: _ctx.$t('userInstruction')
                    },
                          "is-modal": true,
                          class: "page-materials_material"
                        }, null, 8, ["doc"])
                      ], 64))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('notFoundMaterials')), 1))
                ]),
                _: 1
              }, 512), [
                [_vShow, !_ctx.isOpenMenu]
              ]),
              _withDirectives(_createVNode(_component_bottom_navigation, {
                stream: _ctx.stream,
                "current-page": "materials",
                course: _ctx.course
              }, null, 8, ["stream", "course"]), [
                [_vShow, !_ctx.isOpenMenu]
              ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_as_preloader, { class: "-fullpage" })
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon page-materials_wrapper bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, { class: "page-materials_header" }, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, { class: "page-materials_contentWrapper" }, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              class: "page-materials_menu",
              mini: false,
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_row, { class: "-inner" }, {
              content: _withCtx(() => [
                (_ctx.isLoaded)
                  ? (_openBlock(), _createBlock(_component_c_materials, {
                      key: 0,
                      class: "page-materials_materials",
                      "show-title": false
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.courseTitle) + " | " + _toDisplayString(_ctx.$t('documents')), 1)
                      ]),
                      default: _withCtx(() => [
                        (!_ctx.isEmpty)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.documents, (doc) => {
                                return (_openBlock(), _createBlock(_component_c_material, {
                                  key: doc.id,
                                  doc: doc,
                                  class: "page-materials_material"
                                }, null, 8, ["doc"]))
                              }), 128)),
                              _createVNode(_component_c_material, {
                                doc: {
                      url: _ctx.course.instructionUrl,
                      title: _ctx.$t('userInstruction')
                    },
                                "is-modal": true,
                                class: "page-materials_material"
                              }, null, 8, ["doc"])
                            ], 64))
                          : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('notFoundMaterials')), 1))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_as_preloader, { class: "-fullpage" })
                    ]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}